<template>
  <div>
    <div class="logo-container">
      <custom-button variant="secondary" @click="editModal = true">
        <font-awesome-icon icon="pen" slot="icon" />
      </custom-button>
      <figure class="logo">
        <font-awesome-icon :icon="icons.image" v-if="!company.logoURL" />
        <img :src="company.logoURL" alt="company logo" v-else />
      </figure>
      <custom-button
        :disabled="!company.logoURL"
        variant="secondary"
        @click="onDelete"
      >
        <font-awesome-icon icon="trash-alt" slot="icon" />
      </custom-button>
    </div>

    <modal :isOpen="editModal" @close="editModal = false">
      <template slot="title">Subir Logo</template>
      <div v-show="imgSrc" class="crop-area">
        <vue-cropper
          class="cropper"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="imgSrc"
          alt="company logo"
          preview=".preview"
        ></vue-cropper>

        <custom-button type="button" variant="text" @click="clearImage">
          <font-awesome-icon icon="times" slot="icon" size="lg" />
        </custom-button>
      </div>

      <div v-show="!imgSrc" class="upload-area">
        Arrastra una imagen o

        <label class="upload-button">
          Selecciona una imagen

          <input
            type="file"
            accept="image/*"
            class="input"
            @change="setImage"
          />
        </label>
      </div>

      <div class="buttons">
        <custom-button @click="editModal = false" :variant="'secondary'"
          >Cancelar</custom-button
        >
        <custom-button @click="cropImage">Cargar</custom-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { faImage } from "@fortawesome/free-solid-svg-icons";

import { mapActions, mapMutations, mapState } from "vuex";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import CustomButton from "./CustomButton.vue";
import Modal from "./Modal.vue";

export default {
  name: "LogoUploader",

  components: { CustomButton, Modal, VueCropper },

  data: () => ({
    editModal: false,

    formSteps: ["Cargar imagen", "Cortar Imagen"],
    currentStep: 0,

    imgSrc: "",

    icons: {
      image: faImage
    }
  }),

  computed: {
    ...mapState(["survey", "company"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions(["uploadLogo", "deleteLogo"]),

    cropImage() {
      this.imgSrc = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper
        .getCroppedCanvas()
        .toBlob(blob => this.uploadCropImage(blob));
      this.$refs.cropper.replace(this.imgSrc);
      this.editModal = false;
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Por favor, seleccione una imagen");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else alert("FileReader API no compatible");
    },

    clearImage() {
      this.imgSrc = "";
    },

    async onDelete() {
      if (!this.company.logoURL) return;
      if (!confirm("Estás seguro que quieres eliminar el logo?")) return;

      try {
        await this.deleteLogo();

        this.setAlert({
          state: "success",
          message: "Logo eliminado"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    },

    async uploadCropImage(blob) {
      if (!blob) return;

      try {
        await this.uploadLogo(blob);

        this.setAlert({
          state: "success",
          message: "Logo actualizado"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
.logo-container {
  display: inline-flex;
  align-items: flex-start;
  padding: var(--lengthSm2);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.logo {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: var(--grayColor2);
  font-size: var(--lengthLg2);
  border: 1px solid var(--grayColor2);
  border-radius: 50%;
}

.placeholder {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grayColor3);
  font-size: var(--lengthLg3);
  border: 1px solid var(--grayColor3);
}

.upload-area {
  min-width: 400px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthSm1);
  gap: var(--lengthSm1);
  margin-bottom: var(--lengthMd1);
  border: 1px dashed var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.upload-button {
  color: var(--fontColor1);
  font-weight: var(--semi-bold);
  text-decoration: underline;
  cursor: pointer;
}

.input {
  display: none;
}

.crop-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: var(--lengthMd1);
}

.upload-preview {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.upload-preview figure {
  height: 400px;
  margin: 0;
}

.upload-preview img {
  max-height: 100%;
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--lengthMd1);
}
</style>
