<template>
  <modal-2 ref="modal">
    <template slot="title">Cambiar Contraseña</template>

    <custom-form @submit="onSubmit">
      <custom-input
        v-model="newPassword"
        label="Nueva Contraseña"
        type="password"
        rules="required|min:6|confirmed:passwordConfirmation"
      />
      <custom-input
        v-model="passwordConfirmation"
        label="Confirmar Contraseña"
        type="password"
        rules="required|min:6"
        vid="passwordConfirmation"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="closeModal">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary">
          Guardar
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import CustomInput from "./CustomInput.vue";
import CustomButton from "./CustomButton.vue";
import Modal2 from "./Modal2.vue";
import CustomForm from "./CustomForm.vue";

export default {
  name: "ChangePassword",

  components: { CustomInput, CustomButton, Modal2, CustomForm },

  data: () => ({
    newPassword: "",
    passwordConfirmation: ""
  }),

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions(["updatePassword"]),

    async onSubmit() {
      try {
        await this.updatePassword(this.newPassword);

        this.closeModal();

        this.setAlert({
          state: "success",
          message: "Contraseña actualizada"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "No se pudo actualizar, por favor vuelve a intentarlo"
        });

        console.log(err);
      }
    },

    openModal() {
      this.$refs.modal.open();
    },

    closeModal() {
      this.$refs.modal.close();

      this.newPassword = "";
      this.passwordConfirmation = "";
    }
  }
};
</script>
