<template>
  <div class="palette-container" v-click-outside="close">
    <div class="color" :style="stylePalette" @click="active = !active"></div>
    <div :class="active ? 'active selector' : 'selector'">
      <h3>Selecciona un Color:</h3>
      <div class="palette">
        <div
          v-for="color in colors"
          class="color"
          :key="color"
          :style="{ backgroundColor: color }"
          @click="changeColor(color)"
        ></div>

        <div>
          <h3>...mas:</h3>
          <input
            :value="selectedColor"
            @change="e => changeColor(e.target.value)"
            type="color"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  name: "Palette",
  data() {
    return {
      selectedColor: "",
      active: false,
      colors: [
        "#CFFBE2",
        "#A4D6C9",
        "#15B195",
        "#00BEA2",
        "#2B996B",

        "#F3FAFF",
        "#6995BB",
        "#5290F5",
        "#2A99DA",
        "#0054B4",

        "#FD7670",
        "#F9293B",
        "#EA658F",
        "#C462A9",
        "#8E66B7",

        "#FEF9F0",
        "#FFC2B0",
        "#FFBC83",
        "#FFBD53",
        "#F3C319",

        "#DBDBD9",
        "#B8B8B6",
        "#969694",
        "#575755",
        "#2E2E2E"
      ]
    };
  },

  props: {
    startColor: {
      type: String,
      required: true
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    changeColor(color) {
      this.active = false;
      this.selectedColor = color;
      this.$emit("changeColor", this.selectedColor);
    },

    close() {
      this.active = false;
    }
  },
  computed: {
    stylePalette() {
      return {
        backgroundColor: this.selectedColor
      };
    }
  },
  mounted() {
    this.selectedColor = this.startColor;
  }
};
</script>

<style scoped>
.palette-container {
  position: relative;
  cursor: pointer;
  margin: 0.5em 0;
}

.selector {
  display: none;
  padding: 25px;
  padding-top: 20px;
  position: absolute;
  box-shadow: -1px 0px 21px -6px rgba(0, 0, 0, 0.4);
  border-radius: var(--lengthSm3);
  top: 50%;
  right: 50%;
  z-index: 9;
  width: 240px;
  background-color: white;
}

h3 {
  margin-bottom: 10px;
}

.selector.active {
  display: block;
}

.palette {
  display: grid;
  grid-template-rows: repeat(4, 30px);
  grid-template-columns: repeat(5, 30px);
  gap: 10px;
}

input[type="color"] {
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 40px;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 8px;
}

.color {
  height: 30px;
  width: 30px;
  border-radius: 8px;
}

.color:hover,
input:hover {
  transform: scale(1.1);
}
</style>
