<template>
  <modal-2 ref="modal">
    <template slot="title">Activar Encuesta</template>

    <custom-form @submit="onSubmit">
      <p>
        Para activar la encuesta deberás elegir una fecha de fin. Una vez
        llegada la fecha, la encuesta será desactivada y tus colaboradores no
        podrán acceder a su contenido.
      </p>

      <custom-input
        v-model="endingDate"
        label="Fecha de Fin"
        type="date"
        rules="required"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="closeModal">
          Cancelar
        </custom-button>

        <custom-button type="submit" variant="primary">
          Activar
        </custom-button>
      </template>
    </custom-form>

    <confirm-dialogue ref="confirmDialogue" />
  </modal-2>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

import CustomButton from "./CustomButton.vue";
import CustomForm from "./CustomForm.vue";
import CustomInput from "./CustomInput.vue";
import Modal2 from "./Modal2.vue";
import ConfirmDialogue from "./ConfirmDialogue.vue";

export default {
  name: "EnableSurveyModal",

  components: {
    Modal2,
    CustomForm,
    CustomInput,
    CustomButton,
    ConfirmDialogue
  },

  data: () => ({
    endingDate: ""
  }),

  methods: {
    ...mapActions(["activateSurvey"]),

    ...mapMutations(["setAlert"]),

    async onSubmit() {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Activar Encuesta",
        message:
          "¿Estás seguro que quieres activar la encuesta? Una vez activada no podrás modificar su contenido, y las cuentas de usuario serán activadas.",
        isDestructive: false,
        okButton: "Sí, activar",
        cancelButton: "No, volver"
      });

      try {
        if (ok) {
          await this.activateSurvey(this.endingDate);

          this.setAlert({
            state: "success",
            message: "Se activó la encuesta"
          });

          this.closeModal();
        } else
          this.setAlert({
            state: "warning",
            message: "Activación cancelada"
          });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtalo nuevamente"
        });
      }
    },

    openModal() {
      this.$refs.modal.open();
    },

    closeModal() {
      this.$refs.modal.close();

      this.endingDate = "";
    }
  }
};
</script>

<style></style>
